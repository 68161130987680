:root {
  --gutter:30px;
  --listObjectPadding:10px 15px;
  --inputPadding:2px 8px;
  --margin:15px;
  --marginSmall:5px;
  --marginMedium:10px;
  --marginLarge:var(--gutter);
  --borderRadius:7px;
  --fontSmall:0.7rem;
  --fontMedium:0.8rem;
  --fontNormal:1rem;
  --fontLarge:1.2rem;

  /* Colors */
  --gold:#f9f7f1;
  --red:#ff6765;
  --red:#e48080;
  --green:#b6c383;
  --yellow:#fbf19e;
  --redDark:#a24544;
  --orange:#ff9e65;
  --dark:#222;
  --light:#f8f8f8;
  /* --light:#e8eef1; */
  --gray:#f2f2f2;
  --grayDark:#e3e3e3;

  --blue1:#003b46;
  --blue2:#07575b;
  --blue3:#66a5ad;
  --blue4:#c4dfe6;

  --color1:#1C2321;
  --color2:#7D98A1;
  --color3:#5E6572;
  --color4:#A9B4C2;
  --color5:#EEF1EF;
  --color6:#e6bca9;

  --bg:linear-gradient(to right, var(--gold), var(--light));
  --bg:linear-gradient(to right, var(--color4), var(--color5));
  --bg:var(--color5);
  --borderColor: var(--color2);
  --borderColor: var(--grayDark);

  --btnTransition:all .4s ease;
  --btnPadding:15px 15px;
  --btnSmallPadding:10px 15px;
  --btnMiniPadding:5px 10px;
  --btnBg: linear-gradient(to right, var(--orange), var(--red));
  --btnBg: linear-gradient(135deg, var(--color3), var(--color4));
  --btnBg: var(--color2);
  --iconBtnBg: var(--color4);
  --iconBtnBg: var(--btnBg);
  --transparent: rgba(169,180,194,0.05);

  --bezierTransition: cubic-bezier(.51,.14,.29,1);
  --boxShadow: 0 20px 60px -30px #7d98a1;
  --invertBoxShadow: 0 -20px 60px -30px #7d98a1;
}

* {
  box-sizing: border-box;
}

body {
  line-height: 1.6;
  color:#555;
  color:var(--color2);

}

h1 {
  font-size: calc(40px + 3vw);
  margin:0 auto;
}

p {
  max-width: 350px;
  font-size: .7rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and ( max-width:500px){
  p {
    max-width: 80%;
  }
}


.App {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #e3f0f5;
  transition: all 1s ease;
  padding: 2rem .5rem;
}

.dead {
  background: #000;
  color:#f3f3f3;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.answer {

}

img {
  max-height: 30vh;
}

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

}

.info {
  transition: all 1s ease;
  position: relative;
  display: flex;
  justify-content: center;
  width:100%;

  &:before, &:after {
    content: "";
    width: 61px;
    height: 61px;
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    margin: auto;
    animation: rotate 2s infinite;
    animation-timing-function: linear;
    opacity: 0;
    pointer-events: none;
    border: 7px solid var(--btnBg);
    border-bottom:7px solid transparent;
    // border:7px dashed transparent;
    // border-top: 7px dashed var(--btnBg);
    // border-bottom: 7px dashed var(--btnBg);
  }

  &:after {
    animation-delay: 2s;
    animation-duration: 1s;
    // animation-direction: reverse;
    border: 7px solid var(--bg);
    border-top:7px solid transparent;
    // animation-timing-function: ease-in-out;
    // border:7px dashed transparent;
    // border-left: 7px dashed var(--bg);
    // border-right: 7px solid var(--bg);
  }

  &.loading {


    &:before, &:after {
      opacity: 1;
    }
  }
}

.popular {
  text-align: left;
  background: rgba(255,255,255,0.4);
  padding: 2rem;
  border-radius: var(--borderRadius);
  max-width: 300px;

  h3 {
    font-size: 1rem;
    margin:0 0 .2rem 0;

    &:after {
      width: 100%;
      height: 1px;
      margin:.5rem auto 0;
      content: "";
      background: #fff;
      display: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    // padding: 0 0 0 1.2rem;
    font-size: .8rem;
  }

  li {
    cursor: pointer;
    padding: 0;
    list-style: none;

    &:before {
      content: "•";
      margin-right: .5rem;
    }
  }
}
