
.input-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  margin-bottom: var(--marginLarge);
  width: 300px;
  padding:var(--margin);
  /* background: var(--light); */
  border-radius: 5px;
  /* border:1px solid var(--borderColor); */
  position: relative;
}

form .input-wrapper {
  background: none;
  padding: 0;
}


.input-wrapper label {
  margin-bottom: var(--marginSmall);
  font-size: var(--fontMedium);
}

input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
  height: 45px;
  border:2px solid var(--borderColor);
  /* border:2px solid #fff; */
  /* border:2px solid var(--color6); */
  font-size: var(--fontNormal);
  border-radius: 5px;
  padding:var(--inputPadding);
  width:100%;
  font-family: inherit;
  /* color: var(--color2); */
  color: var(--color3);
  font-size: var(--fontMedium);
  outline: none;
  -webkit-appearance: none;
}

@media screen and (max-width:768px){
  input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
    font-size: 16px;
  }
}

input[type="submit"] {
  background: var(--btnBg);
  color:#fff;
  border:0;
  padding: var(--btnPadding);
  height: auto;
  cursor: pointer;
  font-size: inherit;
  border-radius: 5px;
  outline: none;
}

.flex-row > .input-wrapper {
  margin:var(--marginSmall);
  width: calc( 50% - var(--marginSmall)*2)
}

input + [class*="btn"]{
  position: absolute;
  right: var(--marginMedium);
  bottom: 0;
  margin-bottom: var(--margin);
  height: 45px;
  /* border:2px solid var(--borderColor); */
  border-left: 0;
  border-radius:0 5px 5px 0;
}
