
.btn, [class*="btn-"] {
  padding:10px 20px;
  padding:var(--btnPadding) ;
  background: #7D98A1;
  background: var(--btnBg);
  /* border:2px solid rgba(155,155,155,1); */
  /* border:2px solid #444; */
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s ease;
  margin:1rem 0;
  color: #444;
  color: #fff;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  font-size: 1.0rem;
  text-align: center;
  text-decoration: none;

  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  appearance:none;
  line-height: 1;
}

.btn--icon i, .btn--icon  i {
    margin-right: 5px;
}



.btn--small {
  padding:var(--btnSmallPadding);
  font-size: var(--fontMedium);
  width: auto;
}

.btn--mini {
  padding:var(--btnMiniPadding);
  font-size: var(--fontSmall);
  width: auto;
}


.btn:hover {
  transform: scale(1.02);
}

.btn.clicked {
  transform: scale(.95);
  transition: all .1s ease-in-out;
  box-shadow: inset 0 0 0px 0 rgba(30,30,30,0.5);
  box-shadow: inset 0 0 8px 0 rgba(30,30,30,0.1);
  background: var(--color3);
}

.btn.mouse-down {
  animation: btnShiver .6s infinite;
}


.btn + .btn {
  /* margin-top: 15px; */
}

@keyframes btnShiver {
  0%,100% {
    transform: rotate(0) scale(1);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: rotate(-2deg);
  }
  20%, 40%, 60%, 80% {
    transform: rotate(2deg);
  }
}
